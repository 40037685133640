 #get_quote {
     padding: 12px 15px;
     background: #FF6000 !important;
     color: #000;
     font-weight: 600;
     letter-spacing: 0.6px;
 }

 @media screen and (max-width: 600px) {
     .navbar #get_quote {
         text-transform: capitalize !important;
         padding: 6px 8px;
     }

 }

 .navbar {
     padding: 0 !important;
     margin: 0;
 }

 .navbar_outer {
     background: #dad9d9 !important;
 }

 .navItem {
     color: #000 !important;
 }