.QuickAboutUs {
    padding: 10px 0 30px 0;
}

.quick-about-us {
    margin: 100px 150px;
    padding: 10px 50px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: start !important;
    align-items: start;
    /* background-color: #F5F6F9; */
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

@media screen and (max-width:900px) {
    .quick-about-us {
        margin: 50px 0;
        padding: 30px;
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: start !important;
        align-items: start;
        /* border: 1px solid #ccc; */
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }


    .company-info {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .company-info img {
        height: 300px;
        width: 300px;
    }

    .vertically-long-img {
        height: 500px !important;
        width: auto;
        object-fit: cover;
        border-radius: 0.5rem;
    }

    .about_us_heading {
        padding: 20px !important;
    }







    .about-us-page {
        padding: 10px !important;
        /* border: 1px solid; */
        margin: 0 !important;
        overflow: hidden;
    }

    .about-us-page .image {
        display: none;
        overflow: hidden;
        border-radius: 10px;
    }

    .visionMission {

        margin: 10px !important;
        display: none;
    }

    .about-us-page .about_header .text {

        padding: 0 !important;


    }


    .about-us-page .text {

        text-align: start;
        padding: 10px !important;
        color: #666;
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 10px;

    }
}

.quick-about-us h1 {
    text-align: start;
}


.company-info {
    display: flex;
    align-items: center;
}

.company-logo {
    width: 400px;
    height: auto;
    margin-right: 20px;
}

.company-details {
    flex: 1;
}

.quick-about-us h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.quick-about-us p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
}

.quick_about_header {
    width: 100%;

}

.quick-about-us .know_more_btn {
    border-radius: 3px !important;
    font-weight: 500;

}

.vertically-long-img {
    height: 600px;
    width: auto;
    object-fit: cover;
    border-radius: 0.5rem;
}

.about_us_heading {
    padding: 20px 150px;
}



.about-us-page .contact p {
    cursor: pointer;
    font-weight: 500;
    color: #666a;
}

.connect_btn {
    border: 1px solid #1A56DB;
    padding: 10px 30px;
    border-radius: 4px;
    background-color: #1A56DB;
    color: #fff;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.connect_btn:hover {
    transform: translateY(-5px);
}


/* //  */

.features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.feature-box {
    text-align: center;
    max-width: 250px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.feature-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.feature-box h3 {
    font-size: 1.5em;
    margin-top: 10px;
}

.feature-box p {
    margin-top: 10px;
    font-size: 0.9em;
    line-height: 1.4;
}

.more_btn {
    text-transform: capwiitalize;
    letter-spacing: 0.5px;
    width: 150px;
    font-size: 18px;
    background-color: #1465F1 !important;
    color: #fff !important;
    font-weight: 500;

}

.more_btn:hover {
    background: none !important;
    color: #000 !important;
    border: 1px solid;
}




@media (max-width: 768px) {
    .heading_h1 {
        font-size: 2rem;
    }

    .features {
        flex-direction: column;
        gap: 10px;
        margin: 5px;
        align-items: center;
    }

    .feature-box {
        width: 100%;
        margin: 0;
    }

    .company-info {
        padding: 5px;
    }

    .more_btn {
        width: 100%;
        margin-top: 20px;
    }

    .QuickAboutUs {
        display: flex;
        justify-content: center;
        align-items: center;


    }

    .quick-about-us {
        padding: 5px;
    }
}

/* Tablet Styles */
@media (min-width: 769px) and (max-width: 1024px) {
    .heading_h1 {
        font-size: 2.25rem;
    }

    .features {
        gap: 15px;
    }

    .feature-box {
        max-width: 250px;
        width: 100%;
    }

    .more_btn {
        width: auto;
        margin-top: 15px;
    }
}

.about-us-page {
    padding: 10px 140px;
}

.about-us-page .image {
    height: 500px;
    overflow: hidden;
    border-radius: 10px;
}

.about-us-page .about_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-us-page .text {

    text-align: center;
    padding: 20px 100px;
    color: #666;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 10px;

}

.about-us-page .heading_1 {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.7px;
}

.about-us-page .divider {
    width: 40%;
}

.core-strength {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
}

.core-strength h2 {
    font-size: 28px;
    font-weight: 500;
    text-transform: capitalize !important;
    margin: 10px 0;
    letter-spacing: 0.7px;
}

.core-strength .core-strength-insider h2 {
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize !important;
    margin: 10px 0;
    letter-spacing: 0.7px;
}

.core-strength p {
    font-size: 15px;
    font-weight: 400;
    color: #666;
}

.core-strength img {
    border: 1px solid;
    padding: 14px;
    border-radius: 10px;
}

.why_choose_box {
    margin: 20px 0;
    transition: transform 0.6s;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;

}

.why_choose_box h3 {
    font-weight: 500;
}

.why_choose_box:hover .flip-180 {
    transform: rotateY(360deg);
}

.why_choose_box .flip-180 {
    transition: transform 0.7s;
}


.visionMission {
    background: #B5CFB7 !important;
    /* border: 1px solid; */
    border-radius: 10px;
    margin: 50px 0 100px 0;

}

.visionMission ul li {
    list-style: none;
    display: flex;
    margin: 6px 0;
    align-items: center;
}

.visionMission h2 {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.7px;
    margin: 10px 0;
}

.visionMission .star_icon {
    color: #FF6000;
}

@media screen and (max-width: 600px) {
    .core-strength .core-strength-insider img {
        width: 80px;
        height: 80px;
    }

}