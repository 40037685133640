.news_feed {
    padding: 20px 140px;
    margin: 30px 0;
}


.news_feed h1 {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #151717;
    margin: 10px 0;
    /* text-align: center; */
}

.news_card {
    width: 340px;

}

.card_image {
    /* border: 1px solid; */
    height: 200px;
}

.divider {
    margin-bottom: 20px;
    padding: 4px;
    color: #EEEDEB;
}

@media screen and (max-width: 700px) {
    .newsFeed_container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .news_feed {
        padding: 10px;

        width: 97%;
        overflow: hidden;
    }

    .news_card {
        width: 95%;

    }

}