.outer_contact_wrapper {
    height: auto;
}

.contact_container {
    padding: 20px;
    border-radius: 0 0 0 35%;
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
}

.contact_container h1 {
    font-size: 24px;
    font-weight: 600;
}

.divider {
    width: 100%;
}

.contact_form_wrapper {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    margin: 20px auto;
    max-width: 1200px;
    padding: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ffffff;
    padding: 20px;
    width: 100%;
    max-width: 500px;
    border-radius: 20px;
}

.inputForm {
    border: 1.5px solid #ecedec;
    border-radius: 10px;
    display: flex;
    align-items: center;

    transition: 0.2s ease-in-out;
}

.input {
    /* margin-left: 10px; */
    margin: 0;
    border-radius: 10px;
    border: none;
    width: 100%;
    height: 40px;
    padding: 10px
}

.textarea {
    resize: none;
    height: 150px;
}

.input:focus {
    outline: none;
}

.inputForm:focus-within {
    border: 1.5px solid #478af7;
}

.flex-column>label {
    color: #151717;
    font-weight: 600;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.span {
    font-size: 14px;
    margin-left: 5px;
    color: #2d79f3;
    font-weight: 500;
    cursor: pointer;
}

.button-submit {
    margin: 20px 0;
    background-color: #151717;
    border: none;
    color: white;
    font-size: 15px;
    font-weight: 500;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    cursor: pointer;
}

.button-submit:hover {
    background-color: #252727;
}

.p {
    text-align: center;
    color: black;
    font-size: 14px;
    margin: 5px 0;
}

.btn {
    margin-top: 10px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    gap: 10px;
    border: 1px solid #ededef;
    background-color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.btn:hover {
    border: 1px solid #2d79f3;
}

.contact_container .content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact_container .content img {
    width: 50px;
}

.contact_container .content h5 {
    font-weight: 500;
}

.contact_container .content .address .address_box {
    border: 1px solid #B6BBC4;
    padding: 10px;
    border-radius: 8px;
    margin: 0 10px 7px 0;
    width: 100%;
    transition: all 0.2s ease-in-out;
}

.contact_container .content .address .address_box:hover {
    transform: translateX(8px);
}

.address_box h5 {
    color: #7f8082;
    cursor: pointer;
}

.address_box img {
    width: 40px;
    height: 40px;
}

@media screen and (max-width: 900px) {
    .contact_form_wrapper {
        flex-direction: column;
        margin: 20px;
        margin: 2px;
        padding: 4px;
    }

    .contact_container .content {
        width: 100%;
        border: none;
        margin: 20px 0;

    }

    .contact_container .content .address {
        width: 100%;
        margin: 20px 0;

    }

    .contact_container .address_box img {
        width: 40px;
        height: 40px;
    }

    .contact_container .content .address .address_box {
        padding: 10px;
        border-radius: 10px;
        margin: 0 0 7px;
        width: 100%;
    }

    .contact_container form {
        width: 100%;

    }

    .contact_container {
        padding: 10px;
    }

    .contact_info {
        /* border: 1px solid; */
        align-items: start;
        justify-content: start;
    }

    .contact_info .contact_icon {
        height: 30px;
        width: 30px;
        /* border: 1px solid; */
    }

    .contact_info p {
        font-size: 14px;
    }

    .mail-ids {
        overflow: hidden;
    }
}

.card {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    width: 100%;
}

/* Social containers */
.socialContainer {
    width: 52px;
    height: 52px;
    background-color: rgb(44, 44, 44);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition-duration: 0.3s;
    border-radius: 4px;
}

/* Instagram */
.containerOne:hover {
    background-color: #d62976;
}

/* Twitter */
.containerTwo:hover {
    background-color: #00acee;
}

/* LinkedIn */
.containerThree:hover {
    background-color: #0072b1;
}

/* WhatsApp */
.containerFour:hover {
    background-color: #128C7E;
}

.socialContainer:active {
    transform: scale(0.9);
}

.socialSvg {
    width: 17px;
}

.socialSvg path {
    fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
    animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}