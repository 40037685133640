.product-details-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 140px;
    gap: 40px;
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
}

.product-details-container .contact-form-section {
    /* flex: 1; */
    /* max-width: 300px; */
    border-radius: 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.product-details-container .contact-form-section h1 {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.6px;
    margin: 10px;

}

.product-details-section {
    /* flex: 2; */
    max-width: 600px;
    padding: 15px;
    border-radius: 10px;
}

.product-details-section img {
    width: 100%;
    height: 450px;
    margin-bottom: 20px;
    border: 0.5px solid #ddd;
    border-radius: 8px;
}

.product-details-section h1 {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.7px;
    margin-bottom: 10px;
}

.product-details-section .shortDesc {
    font-size: 18px;
    margin-bottom: 10px;
}

.contact-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px !important;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
    margin-bottom: 20px;

}

.contact-form form div {
    margin-bottom: 15px;
}

.contact-form label {
    display: block;
    margin-bottom: 5px;
}

.contact-form .input {
    border: 2px solid red;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    /* padding: 10px; */
    border: 1px solid red;
    border-radius: 4px;
}

.contact-form button {
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #0056b3;
}

.product-details-section .extra-details li {
    list-style: none;
    display: flex;
}

.product-details-section .extra-details img {
    height: 30px;
    width: 30px;
    margin-right: 6px;
}

@media screen and (max-width: 700px) {
    .product-details-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: column-reverse;
        padding: 10px;
        gap: 40px;
    }

    .product-details-section img {
        width: 100%;
        height: 280px;
        margin-bottom: 20px;
        border: 0.5px solid #ddd;
        border-radius: 8px;
    }

    .product-details-container .contact-form-section h1 {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.6px;
        margin: 10px;

    }

}