/* HomePage.css */

.home-page {
  font-family: Arial, sans-serif;
  color: #333;
}

#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #EFEFEF;
  padding: 14px 20px;
  color: #000;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.logo {
  font-size: 1.5em;
  font-weight: bold;
}

nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

nav ul li {
  width: 100px;
  margin: 0 8px;
  text-align: center;
}

nav ul li a {
  color: #000;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: 0.6px;
}


nav ul li a:hover {
  transform: scale(1.09);
  font-weight: 500;

}

.cta-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.hero {
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.hero-content h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.hero-content p {
  font-size: 1.2em;
}

.about-preview,
.product-highlights,
.why-choose-us,
.testimonials,
.contact-section {
  padding: 40px 20px;
  text-align: center;
}

.about-preview p,
.product-highlights p,
.why-choose-us ul,
.testimonials p {
  max-width: 800px;
  margin: 0 auto;
}

.learn-more {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

.product-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.product {
  max-width: 300px;
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.product h3 {
  margin-bottom: 10px;
}

.why-choose-us ul {
  list-style: none;
  padding: 0;
}

.why-choose-us li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.why-choose-us li svg {
  margin-right: 10px;
  color: #007bff;
}

.testimonials {
  background-color: #f0f0f0;
  padding: 40px 20px;
}

.testimonials .header h2 {
  font-family: "Poppins", sans-serif !important;
  font-size: 30px;

}

@media screen and (max-width: 600px) {
  .testimonials {

    padding: 10px 10px 100px 10px !important;
  }
}

.testimonial {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
}

.testimonial svg {
  font-size: 2em;
  color: #007bff;
  margin-bottom: 10px;
}

.testimonial p {
  font-style: italic;
}

.contact-section .contact-info {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
}

.contact-info p {
  margin: 10px 0;
  font-size: 1.1em;
}

.contact-info p svg {
  margin-right: 10px;
}

footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.social-media {
  margin-top: 10px;
}

.social-media a {
  color: white;
  margin: 0 10px;
}


.download-brochure {
  padding: 60px 140px;
  text-align: center;
  background-color: #F0EBE3;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.download-brochure .content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.download-brochure h2 {
  font-size: 30px;
  font-weight: 500;
  margin: 10px 0;
  letter-spacing: 0.7px;

}


.download-brochure .image {
  height: 420px;
  width: 320px;

  margin: 20px 0;
  border-radius: 4px;
  overflow: hidden;
}

.download-brochure .image img {
  height: 100%;
  width: 100%;
}

.download-brochure p {
  color: #333;
}

.download-brochure .download-btn {
  width: 70%;
  margin: 20px 0 0 0;
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 0.7px;
}

@media screen and (max-width: 600px) {
  .download-brochure {
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .download-brochure .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .download-brochure h2 {
    font-size: 22px;
  }
}


.counter_box {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter_comp {
  padding: 50px 140px;
  /* margin: 50px 0; */

}

.counter_comp h1 {
  font-size: 30px;
  letter-spacing: 0.7px;
  font-weight: 500;
}

.counter_comp h3 {
  font-size: 20px;
  /* border: 1px solid; */
}

.counter_comp .counter_value {
  font-weight: 600;
}



@media (max-width: 650px) {
  .counter_box {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .counter_comp {
    padding: 50px;
    /* margin: 50px 0; */
    display: flex;
    flex-wrap: wrap;
    padding: 10px 4px;
  }

  .counter_comp h1 {
    font-size: 22px;
    letter-spacing: 0.7px;
    font-weight: 500;
  }

  .counter_comp h3 {
    font-size: 20px;
    /* border: 1px solid; */
  }
}


.schedule_demo_wrapper {
  display: flex;
  /* height: 400px; */
  justify-content: space-between;
  align-items: center;
  padding: 10px 140px;
  margin: 50px 0;
  /* border: 1px solid; */
  height: 250px;
}

.schedule_demo_wrapper h2 {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.7px;
  margin-bottom: 10px;
}

.schedule_demo_wrapper .demo_btn {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.schedule_demo_wrapper .demo_btn Button {
  background-color: #000;
  color: #fff;
  font-weight: 500;
  padding: 12px 20px;
}

.schedule_demo_wrapper p {
  color: #333;
}

@media (max-width: 650px) {
  .schedule_demo_wrapper {
    flex-direction: column !important;
    height: auto;
    padding: 10px;
  }

  .schedule_demo_wrapper .demo_btn {
    margin-top: 14px;
  }
}