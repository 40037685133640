.slide {
    padding: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    overflow: hidden;
    border-radius: 8px;
    margin: 05px 10px !important;

    width: 360px !important;
    height: 500px;
    overflow: hidden;


}

.home_products_container .product_card {
    border: 1px solid #ddd;
    border-radius: 8px;
    /* overflow: hidden; */
    transition: transform 0.2s;
    box-shadow: 0 4px 8px #0000001a;
    height: 500px;
}

.home_products_container .product-image {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    height: 280px;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
}

.product-info {
    flex-grow: 1;
    /* Ensures the product info section takes up available space */
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Space out title, description, and button */
}

.product-info h3 {
    font-size: 1.2em;
    margin-bottom: 5px;
    text-align: left;
}

.product-info p {
    font-size: 0.9em;
    color: #666;
    text-align: left;
}

.view-more-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    /* Pushes the button to the bottom */
    padding: 6px 19px !important;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    width: 140px;
    font-size: 15px;

}

.view-more-button:hover {
    background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .product-info h3 {
        font-size: 1.1em;
    }

    .product-info p {
        font-size: 0.8em;
    }

    .view-more-button {
        padding: 6px 14px;
    }
}

@media (max-width: 600px) {
    .product-info h3 {
        font-size: 1em;
    }

    .product-info p {
        font-size: 0.75em;
    }

    .view-more-button {
        padding: 5px 12px;
    }
}

.custom-arrow {
    background-color: #000 !important;
    z-index: 999;
}

/* 
.slider-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    height: 60vh !important;
} */

.products_heading {
    padding: 0 140px;
}

.products_heading h1 {
    font-size: 30px;
    letter-spacing: 0.7px;
    margin: 10px 0;
    font-weight: 500;
}

.products_heading p {
    color: #333;
}

.home_products_container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    height: 700px;

}

.home_products_container .product_card {
    border: 1px solid #ddd;
    border-radius: 8px;
    /* overflow: hidden; */
    transition: transform 0.2s;
    box-shadow: 0 4px 8px #0000001a;
    height: 500px;
}

@media screen and (max-width: 700px) {
    .home_products_container {
        height: 100vh;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .slide {
        padding: 10px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        overflow: hidden;
        border-radius: 8px;
        margin: 0 10px !important;

        width: 300px !important;
        height: 500px;



    }


    .slider-container {

        width: 100% !important;
        margin: 0 !important;
    }
}