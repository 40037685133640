body {
  font-family: "Poppins", sans-serif;

}

:root {
  --primary-color: #1465F1;
  --primary-color-dark: #1A56DB;
  --secondary-color: #F0F5FF;
  --light-white-bg: #F2F4F8;
  --color-grey: #8996A9;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.color_gray {
  color: #8996A9;
}

.filled_btn {
  min-height: 48px;
  width: auto;
  cursor: pointer;
  background-color: rgb(19, 100, 241);
  border-color: rgb(19, 100, 241);
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  padding: 0px 20px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
  color: #fff;
  margin-right: 10px;
  transition: all ease-in-out 0.2s;
  font-weight: 600;
}

.filled_btn_second {
  min-height: 40px;
  width: auto;
  cursor: pointer;
  background-color: rgb(19, 100, 241);
  border-color: rgb(19, 100, 241);
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  padding: 0px 20px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
  color: #fff;
  margin-right: 10px;
  transition: all ease-in-out 0.2s;
  font-weight: 600;
}

.filled_btn:hover {
  background-color: var(--primary-color-dark);
  transform: translateY(-3px);

}

.heading_h1 {
  font-size: 30px;
  font-weight: 500 !important;
  margin: 10px 0;
}

.a {
  font-size: 30px;
  font-weight: 500 !important;
  margin: 10px 0;
}

.heroo_section {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  background: var(--primary-color);
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #F0F5FF, #F0F5FF);
  background: linear-gradient(to right, #FFFFFF, #F0F5FF, #F0F5FF);
  border: 1px saddlebrown solid;
}

.hero_section h1 {
  font-size: 3rem;
  font-weight: 700;
  color: var(--primary-color);
}

.hero_section span {
  font-size: 2.5rem;
  font-weight: 700;
}


.hero_section .tag-line p {
  font-weight: 400;
  color: rgb(94, 110, 135);
}

.hero_slider {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid green;
  padding: 0;
  margin: 0;
  width: 100%;
}

.primary-btn:hover {
  background-color: var(--primary-color-dark);
}

.btns .sec-btn {
  color: var(--primary-color);
  font-weight: 600;
}



/* admission  */

.feature_parent {
  background-color: rgb(255, 255, 255);
  opacity: 1;
  transform: scale(0.988311) translateZ(0px);
  padding: 50px 20px;
  height: 600px;
}


.feature_parent .header h1 {
  font-size: 2rem;
  font-weight: 700;
  font-style: normal;
  text-decoration-line: none;
  line-height: 2.5rem;
  margin: 0px 0px 12px;
  padding: 0px;
}

.slider {
  margin: 0 10px;
  overflow: hidden;
}

.slider .card {
  background-color: rgb(255, 255, 255);
  transform: scale(0.99) translateZ(0px);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px !important;
  border: 2px solid red;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px; */
  position: relative;
  overflow: hidden;
  height: 420px;
  width: 300px;
  border-radius: 5px;


}

.slider .details {
  /* position: absolute; */
  /* top: 50px; */
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  box-sizing: border-box;
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.slider .card:hover .details {
  transform: translateY(-50%);
}

.slider img {
  width: 100%;
  height: 300px;
}

.slider .desc h3 {
  font-weight: 600;
  font-size: 18px;
  height: 40px;
}

.slider .desc p {
  font-size: 14px;
  color: var(--color-grey);
  margin-bottom: 13px;
  padding: 8px 0;
  height: 50px;
}

.button-container {
  text-align: center;
  /* display: none; */
  margin-top: 9px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary-color);
  display: inline-flex;
  align-self: center;
  text-align: center;
  padding: 9px 12px;
  color: var(--primary-color);
  cursor: pointer;

}

.slider .card:hover .filled_btn {
  opacity: 1;
}


.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button {
  border: none;
  background: rgb(255, 68, 68);
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: rgb(255, 68, 68) !important;
}

/*  navbar  */


.slider-container {
  width: 100%;
  /* overflow: hidden; */
  height: 100vh;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.slider_under {
  width: 100%;
}

/* faqs */

.faqs_section {
  padding: 60px 120px;
  margin: 40px 0;

}

@media screen and (max-width:900px) {
  .faqs_section {
    padding: 10px !important;
    margin: 40px 5px;
    /* border: 2px solid; */
  }

  .faqs_section .content {
    padding: 8px 1px !important;
  }

  .heading_h1 {
    font-size: 26px;
    font-weight: 500 !important;
  }
}

.faqs_section .content {
  padding: 0px 50px;
}

.faqs_section h1 {
  font-size: 28px;
  font-weight: 600;
}

.divider {
  width: 50%;
}

.divider_color_black {
  background-color: #000;
  width: 40%;
  color: #000;
  border: 0.4px solid;
  margin: 4px 0 20px 0;
}

/* navbar lower and upper*/

.upper_header {
  display: flex;
  justify-content: space-between;
  padding: 7px 30px;
  background-color: #000;
}

.upper_header .contact {
  display: flex;
  color: #fff;
  font-weight: 500;
}

@media screen and (max-width:768px) {
  .upper_header {
    display: none;
  }


}

/* Header.css */

.upper_header .social-icons i {
  border: 1px solid #fff;
  transition: all .2s ease-in-out;
  background-color: #fff;
  color: #000;
}


.upper_header .social-icons i:hover {
  transform: scale(1.1);
}

.upper_header .contact div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

}

.upper_header .contact div p {
  margin-left: 5px;
}

.upper_header .contact div:hover {
  transform: translateY(-3px);
}



.pattern {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-image: radial-gradient(rgba(12, 12, 12, 0.1) 2px, transparent 0);
  background-size: 30px 30px;
  background-position: -5px -5px;
}



.feedback_text {
  font-weight: 400;
}

.testimonials {
  background-color: #BFDCE5;
  border-radius: 100%/0 0 150px 150px;
  padding: 50px 100px 80px 100px;
}

.testimonials .header {}

.testimonials .header h2 {
  color: #000;
  width: 100%;
  font-size: 30px;
}

.testimonials .header p {
  color: #333;
  font-size: 18px;
}


.span {
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 31px !important;
  background-color: #FF6000;
  padding: 0 10px;
}

@media screen and (max-width: 600px) {
  .span {
    font-size: 22px !important;
  }

}

/* feqs  */


.faq-container {
  width: 100%;
  /* max-width: 1000px; */
  margin: 30px auto;
  padding: 20px 140px;
}

.faq-container .content {
  padding: 0px 110px;
}

@media screen and (max-width:900px) {
  .faq-container .content {
    width: 100%;
    padding: 0;

  }

  .faq-container {
    width: 100%;
    padding: 10px;
    /* margin: 10px; */

  }
}

.faq-heading {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #333;
  text-transform: capitalize;
}


.faq-item {
  margin-bottom: 15px;
  /* Adds space between each FAQ item */
  border: 1px solid #ddd;
  /* Adds a light border around each FAQ */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for a lifted effect */
  border-radius: 8px;
  /* Adds rounded corners */
  overflow: hidden;
  /* Ensures content doesn't overflow rounded corners */
}

.faq-question {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.faq-answer {
  font-size: 16px;
  color: #555;
}

.MuiAccordionSummary-content {
  align-items: center;
}




/* Basic Styles */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: white;
}

.logo img {
  width: 150px;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.cta-button {
  background-color: #ff6200;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.cta-button:hover {
  background-color: #e55d00;
}

/* Responsive Styles */
@media (max-width: 768px) {
  header {
    flex-direction: column;
    align-items: flex-start;
  }

  .logo img {
    width: 120px;
  }

  nav ul {
    flex-direction: column;
    gap: 10px;
    margin: 10px 0;
  }

  .cta-button {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  nav ul {
    display: none;
    /* Hide navigation on small screens */
  }

  .menu-toggle {
    display: block;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }

  .menu-toggle:focus+ul {
    display: flex;
    /* Show navigation when toggle is clicked */
    flex-direction: column;
    gap: 10px;
  }
}


.breadcrumbs {
  display: flex;
  justify-content: start;
  margin: 20px 0 6px 100px;
}

@media screen and (max-width: 600px) {
  .breadcrumbs {
    display: flex;
    justify-content: start;
    margin: 10px;
  }

}

.whatsapp-input {
  color: #000 !important;
  /* Change this to your preferred color */
  border: 2px solid red;
}