.products_container {
    padding: 20px 50px;
}

.products_heading {
    text-align: center;
    margin-bottom: 20px;
}

.products_container .products_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    gap: 20px;
}

.products_container .product_card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 500px;
    width: 100%;
}

.product_card:hover {
    transform: scale(1.05);
}

.products_container .product-image {
    width: 100%;
    height: 260px !important;
    /* object-fit: cover; */
}

.product-image img {
    height: 100%;
}

.product-info {
    padding: 15px;
    text-align: center;
}

.view-more-button {
    /* display: flex; */
    /* border: 1px solid; */
    /* padding: 8px 15px; */
    /* background-color: green; */
    /* color: #fff; */
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.2s;
}

.view-more-button:hover {
    background-color: darkgreen;
}

.products_grid .buttons {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px;
    flex-grow: 3;
}

.products_grid .buttons .view-more {
    display: flex;

}

.products_grid .buttons .view-more:hover {
    display: flex;
    color: #2f2f2f;

}

.product_card .buy-now-button {
    background: #000;
    border-radius: 8px;
    padding: 9px 14px;
    color: #fff;
}

.product_card .buy-now-button:hover {
    background: #2f2f2f;
    border-radius: 8px;
    padding: 9px 14px;
    color: #fff;
}

@media screen and (max-width: 700px) {
    .products_container {
        padding: 20px;
    }

    .products_heading {
        text-align: start;
        margin-bottom: 20px;
        padding: 0;
    }

    .products_heading h1 {
        font-size: 20px;
    }


    .products_container .product_card {
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        transition: transform 0.2s;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        height: 500px;
        width: 95%;
    }


}