.hero_section {
    width: 100%;
    height: 86vh;
    overflow: hidden;
    width: 100%;

}

@media screen and (max-width: 700px) {
    .hero_section {
        height: 30vh;
    }
}