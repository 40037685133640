.footer_wrapper {
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    padding: 50px 140px;


}

.footer_wrapper .head {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 50px 0;
}

.footer_wrapper .head p {
    color: #333;
    font-size: 20px;
    letter-spacing: 0.7px;
    font-style: italic;
    font-weight: 500;
}

.footer_wrapper .location {
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.footer_wrapper .location div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 15px;
    /* overflow: hidden; */
    border: 1px solid #AEAEAE;
    color: #000;
    cursor: pointer;

    border-radius: 5px;
    margin: 0 10px;
}

.footer_wrapper .location div img {
    height: 30px;
    width: 30px;
    margin-right: 8px;
}

.footer_wrapper .location div p {
    font-size: 17px;
}

.footer_wrapper .links {
    display: flex;
    justify-content: space-between;
}

.footer_wrapper .company {
    width: 200px;
}

.footer_wrapper .company h3 {
    font-size: 20px;
    margin-bottom: 8px;
    color: #FF6000;
}

.footer_wrapper .company span {
    font-size: 13px;
    color: #AEAEAE;
}

.footer_wrapper .links h4 {
    margin-bottom: 10px;
    letter-spacing: 1px;
}

.footer_wrapper .links p {
    font-size: 15px;
    color: #AEAEAE;
    margin-bottom: 5px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
}

.footer_wrapper .links p:hover {
    color: #323131;
}

.footer_wrapper hr {
    margin: 40px 0 20px 0;
    border: 0.5px solid #AEAEAE;

}

.footer_last p {
    font-size: 14px;
    color: #AEAEAE;
}

@media (max-width:700px) {
    .footer_wrapper .links {
        flex-direction: column;
    }

}

@media (max-width:768px) {
    .footer_wrapper {
        padding: 50px;
    }

    .footer_wrapper .social-icons i {

        height: 40px;
        width: 40px;
        size: 50px !important;
    }
}

.social-icons {
    /* border: 1px solid; */
    display: flex;
}

.social-icons i:hover {
    transform: scale(1.1);

}

.social-icons i {
    background-color: #000;
    padding: 6px;
    margin-right: 7px;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40%;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.logo_box {
    height: 27px;
    width: 127px;
    margin-right: 10px;
}

.logo_box img {
    height: 100%;
    width: 100%;
    /* border: 2px solid green; */
    overflow: hidden;
}

.footer_wrapper h4 {
    text-transform: uppercase;
}

.download_app div {
    width: 250px;
    /* height: 2.5rem; */
    overflow: hidden;
    margin: 10px 0;
    /* border: 1px solid; */
}

.download_app div img {
    width: 100%;
    height: 100%;

}

.footer-logos {
    /* border: 1px solid red; */
    display: flex;
    width: 250px;
    justify-content: space-between;
    align-items: center;
}


.footer-logos .logo-2 {
    /* border: 2px solid green; */
    height: 80px;
    width: 80px;
    margin-left: 10px;
}


.footer-logos .logo-1 {
    height: 120px;
    width: 120px;

}